import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface EmbeddedState {
  isEmbedded: boolean;
}

export const initialEmbeddedState: EmbeddedState = {
  isEmbedded: false,
};

export const embeddedSlice = createSlice({
  name: 'embedded',
  initialState: initialEmbeddedState,
  reducers: {
    setEmbeddedMode: (state, action: PayloadAction<EmbeddedState['isEmbedded']>) => {
      state.isEmbedded = action.payload;
    },
  },
});

export const { setEmbeddedMode } = embeddedSlice.actions;

export default embeddedSlice.reducer;
