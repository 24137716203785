import { useMemo } from 'react';
import { useRouter as useSolitoRouter } from 'solito/navigation';
import { useNextRouter } from './useNextRouter';
import { Platform } from 'react-native';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useAppSelector } from 'app/store';

/**
 * Wrapper over solito router, with next router specific methods, similar to how solito wraps next router and react navigation
 * https://github.com/nandorojo/solito/blob/master/src/router/use-router.ts
 */
export const useRouter = () => {
  const solitoRouter = useSolitoRouter();
  const nextRouter = useNextRouter();
  const isEmbedded = useAppSelector((state) => state.embedded.isEmbedded);

  return useMemo(
    () => ({
      ...solitoRouter,
      prefetch: (
        href: Parameters<AppRouterInstance['prefetch']>[0],
        options?: Parameters<AppRouterInstance['prefetch']>[1]
      ) => {
        if (Platform.OS === 'web') {
          nextRouter?.prefetch(href, options);
        } else {
          // Do nothing
        }
      },
      push: (href: Parameters<typeof solitoRouter.push>[0], _paramName?: string, _paramValue?: string) => {
        if (isEmbedded && !href.startsWith('/embedded/')) {
          solitoRouter?.push(`/embedded/${href}`);
        } else {
          solitoRouter.push(href);
        }
      },
      replace: (href: Parameters<typeof solitoRouter.push>[0], _paramName?: string, _paramValue?: string) => {
        if (isEmbedded && !href.startsWith('/embedded/')) {
          solitoRouter?.replace(`/embedded/${href}`);
        } else {
          solitoRouter.replace(href);
        }
      },
      refresh: () => {
        if (Platform.OS === 'web') {
          nextRouter?.refresh();
        } else {
          // Do nothing
        }
      },
    }),
    []
  );
};
