'use client';

import { useTranslation } from 'react-i18next';
import { FullScreenError } from './components';

export function InvalidSessionError() {
  const { t } = useTranslation();

  return (
    <FullScreenError
      variant="with-logo-and-background"
      title={t('common.errorBoundary.title.invalidSession')}
      message={t('common.errorBoundary.message.invalidSession')}
    />
  );
}
