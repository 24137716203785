import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { appClient } from 'packages/app/utils/appClient';
import { parseAuth0TokenInfo, parseCognitoTokenInfo } from './utils/parseJwt';
import type { TokenInfo } from './types';

export interface AuthState {
  inactivityLogout: boolean;

  // Tokens
  tokenInfo: TokenInfo;
  accessToken: string | null;
  refreshToken: string | null;
  idToken: string | null;

  // Values stored in device storage
  customerNumber: string;
  deviceId: string;
}

export const initialAuthState: AuthState = {
  inactivityLogout: false,

  // Tokens
  tokenInfo: {
    userId: '',
    nickname: '',
    entityAccountId: '',
    permissionIds: [],
    roleIds: [],
    requirementIds: [],
    kycStatus: 'INCOMPLETE',
    permissionsStripped: false,
    intercomHmac: '',
    expiry: null, // Api token expiration time in epoch time milliseconds
  },
  accessToken: null,
  refreshToken: null,
  idToken: null,

  // Values stored in device storage
  customerNumber: '',
  deviceId: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setInactivityLogout: (state, action: PayloadAction<AuthState['inactivityLogout']>) => {
      state.inactivityLogout = action.payload;
    },
    setCustomerNumber(state, action: PayloadAction<AuthState['customerNumber']>) {
      if (appClient === 'MOBILE') {
        state.customerNumber = action.payload;
      }
    },
    setDeviceId(state, action: PayloadAction<AuthState['deviceId']>) {
      state.deviceId = action.payload;
    },
    setTokenInfo(state, action: PayloadAction<AuthState['tokenInfo']>) {
      state.tokenInfo = action.payload;
    },
    resetTokenInfo(state) {
      state.tokenInfo = { ...initialAuthState.tokenInfo };
    },
    setAccessToken(state, action: PayloadAction<AuthState['accessToken']>) {
      // Cannot set accessToken on web
      if (appClient === 'MOBILE') {
        state.accessToken = action.payload;
      }
    },
    setRefreshToken(state, action: PayloadAction<AuthState['refreshToken']>) {
      // Cannot set refreshToken on web
      if (appClient === 'MOBILE') {
        state.refreshToken = action.payload;
      }
    },
    setIdToken(state, action: PayloadAction<AuthState['idToken']>) {
      // Cannot set idToken on web
      if (appClient === 'MOBILE') {
        state.idToken = action.payload;
      }
    },
    setCognitoTokens(
      state,
      action: PayloadAction<{
        accessToken: AuthState['accessToken'];
        refreshToken: AuthState['refreshToken'];
        idToken: AuthState['idToken'];
      }>
    ) {
      // Only set tokens on mobile, as web uses cookies
      if (appClient === 'MOBILE') {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.idToken = action.payload.idToken;
      } else {
        state.accessToken = null;
        state.refreshToken = null;
        state.idToken = null;
      }

      if (action.payload.accessToken && action.payload.idToken) {
        const tokenInfo = parseCognitoTokenInfo({
          idToken: action.payload.idToken,
        });
        if (tokenInfo) {
          state.tokenInfo = tokenInfo;
        } else {
          state.tokenInfo = { ...initialAuthState.tokenInfo };
        }
      }
    },
    setAuth0Tokens(
      state,
      action: PayloadAction<{
        accessToken: AuthState['accessToken'];
        refreshToken: AuthState['refreshToken'];
        idToken: AuthState['idToken'];
      }>
    ) {
      // Only set tokens on mobile, as web uses cookies
      if (appClient === 'MOBILE') {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.idToken = action.payload.idToken;
      } else {
        state.accessToken = null;
        state.refreshToken = null;
        state.idToken = null;
      }

      if (action.payload.accessToken && action.payload.idToken) {
        const tokenInfo = parseAuth0TokenInfo({
          accessToken: action.payload.accessToken,
          idToken: action.payload.idToken,
        });
        if (tokenInfo) {
          state.tokenInfo = tokenInfo;
        } else {
          state.tokenInfo = { ...initialAuthState.tokenInfo };
        }
      }
    },
    setEntityAccountId: (state, action: PayloadAction<string>) => {
      state.tokenInfo.entityAccountId = action.payload;
    },
    resetAuthState() {
      return { ...initialAuthState };
    },
  },
});

export const {
  setInactivityLogout,
  setCustomerNumber,
  setDeviceId,
  setTokenInfo,
  resetTokenInfo,
  setAccessToken,
  setRefreshToken,
  setIdToken,
  setCognitoTokens,
  setAuth0Tokens,
  setEntityAccountId,
  resetAuthState,
} = authSlice.actions;

export default authSlice.reducer;
