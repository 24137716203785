import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface FrankieOneInfo {
  entityId: string | null;
}

const initialF1ConnectState: FrankieOneInfo = {
  entityId: null,
};

export const F1ConnectSlice = createSlice({
  name: 'FrankieOneConnect',
  initialState: initialF1ConnectState,
  reducers: {
    setEntityId: (state, action: PayloadAction<FrankieOneInfo['entityId']>) => {
      state.entityId = action.payload;
    },
  },
});

export const { setEntityId } = F1ConnectSlice.actions;

export default F1ConnectSlice.reducer;
