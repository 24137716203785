import jwtDecode from 'jwt-decode';
import type {
  Auth0AccessTokenPayload,
  Auth0IdTokenPayload,
  CognitoIdTokenPayload,
  PermissionId,
  RequirementId,
  RoleId,
  TokenInfo,
} from '../types';
import { getIntercomHmac } from './getIntercomHmac';

export const claimToArray = <T>(claim: string | string[]): T[] => {
  if (typeof claim === 'string') {
    return claim.split(',').map((element) => element.trim()) as T[];
  }
  return claim.map((element) => element.trim()) as T[];
};

// Parse the Cognito id token to the TokenClaims type
export const parseCognitoTokenInfo = ({ idToken }: { idToken: string }): TokenInfo | null => {
  try {
    const claims = jwtDecode<CognitoIdTokenPayload>(idToken);
    return {
      userId: claims.userId,
      nickname: claims.nickname,
      entityAccountId: claims.entityAccountId,
      permissionIds: claimToArray<PermissionId>(claims.permissionIds),
      roleIds: claimToArray<RoleId>(claims.roleIds),
      requirementIds: claimToArray<RequirementId>(claims.requirementIds),
      kycStatus: claims.kycStatus,
      permissionsStripped: claims.permissionsStripped === 'true',
      intercomHmac: getIntercomHmac(claims),
      expiry: claims.exp * 1000, // Convert epoch time from seconds to milliseconds
    };
  } catch {
    return null;
  }
};

// Parse the Auth0 id token and access token to the TokenClaims type
export const parseAuth0TokenInfo = ({
  idToken,
  accessToken,
}: {
  idToken: string;
  accessToken: string;
}): TokenInfo | null => {
  try {
    const idTokenClaims = jwtDecode<Auth0IdTokenPayload>(idToken);
    const accessTokenClaims = jwtDecode<Auth0AccessTokenPayload>(accessToken);
    return {
      userId: accessTokenClaims.userId,
      nickname: idTokenClaims.nickname,
      entityAccountId: accessTokenClaims.entityAccountId,
      permissionIds: claimToArray<PermissionId>(accessTokenClaims.permissionIds),
      roleIds: claimToArray<RoleId>(accessTokenClaims.roleIds),
      requirementIds: claimToArray<RequirementId>(accessTokenClaims.requirementIds),
      kycStatus: accessTokenClaims.kycStatus,
      permissionsStripped: accessTokenClaims.permissionsStripped,
      intercomHmac: getIntercomHmac(accessTokenClaims),
      expiry: accessTokenClaims.exp * 1000, // Convert epoch time from seconds to milliseconds
    };
  } catch {
    return null;
  }
};
